// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-hire-md": () => import("./../../../src/pages/hire.md" /* webpackChunkName: "component---src-pages-hire-md" */),
  "component---src-pages-index-md": () => import("./../../../src/pages/index.md" /* webpackChunkName: "component---src-pages-index-md" */),
  "component---src-pages-now-md": () => import("./../../../src/pages/now.md" /* webpackChunkName: "component---src-pages-now-md" */),
  "component---src-pages-posts-a-request-wrapper-for-commercejs-md": () => import("./../../../src/pages/posts/a-request-wrapper-for-commercejs.md" /* webpackChunkName: "component---src-pages-posts-a-request-wrapper-for-commercejs-md" */),
  "component---src-pages-posts-build-a-nodejs-graphql-server-md": () => import("./../../../src/pages/posts/build-a-nodejs-graphql-server.md" /* webpackChunkName: "component---src-pages-posts-build-a-nodejs-graphql-server-md" */),
  "component---src-pages-posts-build-your-own-custom-react-hook-md": () => import("./../../../src/pages/posts/build-your-own-custom-react-hook.md" /* webpackChunkName: "component---src-pages-posts-build-your-own-custom-react-hook-md" */),
  "component---src-pages-posts-building-a-apollo-graphql-datasource-for-ecommerce-md": () => import("./../../../src/pages/posts/building-a-apollo-graphql-datasource-for-ecommerce.md" /* webpackChunkName: "component---src-pages-posts-building-a-apollo-graphql-datasource-for-ecommerce-md" */),
  "component---src-pages-posts-create-a-commercejs-store-with-svelte-md": () => import("./../../../src/pages/posts/create-a-commercejs-store-with-svelte.md" /* webpackChunkName: "component---src-pages-posts-create-a-commercejs-store-with-svelte-md" */),
  "component---src-pages-posts-create-your-ownapollo-datasource-package-md": () => import("./../../../src/pages/posts/create-your-ownapollo-datasource-package.md" /* webpackChunkName: "component---src-pages-posts-create-your-ownapollo-datasource-package-md" */),
  "component---src-pages-posts-creating-gatsby-plugins-md": () => import("./../../../src/pages/posts/creating-gatsby-plugins.md" /* webpackChunkName: "component---src-pages-posts-creating-gatsby-plugins-md" */),
  "component---src-pages-posts-friendly-mac-os-defaults-md": () => import("./../../../src/pages/posts/friendly-mac-os-defaults.md" /* webpackChunkName: "component---src-pages-posts-friendly-mac-os-defaults-md" */),
  "component---src-pages-posts-get-up-and-running-with-tailwind-css-and-nextjs-md": () => import("./../../../src/pages/posts/get-up-and-running-with-tailwind-css-and-nextjs.md" /* webpackChunkName: "component---src-pages-posts-get-up-and-running-with-tailwind-css-and-nextjs-md" */),
  "component---src-pages-posts-getting-to-know-ruby-operators-md": () => import("./../../../src/pages/posts/getting-to-know-ruby-operators.md" /* webpackChunkName: "component---src-pages-posts-getting-to-know-ruby-operators-md" */),
  "component---src-pages-posts-introducing-plangram-md": () => import("./../../../src/pages/posts/introducing-plangram.md" /* webpackChunkName: "component---src-pages-posts-introducing-plangram-md" */),
  "component---src-pages-posts-learn-graphql-middleware-in-6-minutes-md": () => import("./../../../src/pages/posts/learn-graphql-middleware-in-6-minutes.md" /* webpackChunkName: "component---src-pages-posts-learn-graphql-middleware-in-6-minutes-md" */),
  "component---src-pages-posts-manage-shopping-carts-with-graphql-md": () => import("./../../../src/pages/posts/manage-shopping-carts-with-graphql.md" /* webpackChunkName: "component---src-pages-posts-manage-shopping-carts-with-graphql-md" */),
  "component---src-pages-posts-shoppable-cms-with-commercejs-md": () => import("./../../../src/pages/posts/shoppable-cms-with-commercejs.md" /* webpackChunkName: "component---src-pages-posts-shoppable-cms-with-commercejs-md" */),
  "component---src-pages-posts-start-teaching-and-sharing-awesome-content-md": () => import("./../../../src/pages/posts/start-teaching-and-sharing-awesome-content.md" /* webpackChunkName: "component---src-pages-posts-start-teaching-and-sharing-awesome-content-md" */),
  "component---src-pages-posts-working-with-stripe-payment-intents-and-nextjs-md": () => import("./../../../src/pages/posts/working-with-stripe-payment-intents-and-nextjs.md" /* webpackChunkName: "component---src-pages-posts-working-with-stripe-payment-intents-and-nextjs-md" */),
  "component---src-pages-uses-md": () => import("./../../../src/pages/uses.md" /* webpackChunkName: "component---src-pages-uses-md" */)
}

